/* eslint-disable react/no-multi-comp */
import * as React from "react";
import { navigate } from "gatsby";
import IndexLayout from "../layouts";
import { useEffect } from "react";

const NewsroomPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return <IndexLayout></IndexLayout>;
};

export default NewsroomPage;
